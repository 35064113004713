define('alarm-clock/routes/edit', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        /**
         * A container for the alarms service.
         *
         * @property alarmsService
         * @type {Service}
         */
        alarmsService: _ember['default'].inject.service('alarms'),

        /**
         * Generates the model for the route.
         *
         * @method model
         * @param {Object} params Dynamic urls parts.
         * @return {Object}
         */
        model: function model(params) {
            var alarm = this.get('alarmsService.alarms').objectAt(params.index);
            var time = alarm.hours + ':';
            if (alarm.minutes > 10) {
                time += alarm.minutes;
            } else {
                time += '0' + alarm.minutes;
            }
            return {
                alarm: alarm,
                time: time,
                index: params.index
            };
        },

        actions: {
            /**
             * Save an alarm.
             *
             * @method saveAlarm
             * @param {Object} alarm The alarm object to save.
             * @return {Void}
             */
            saveAlarm: function saveAlarm(alarm) {
                var alarms = this.get('alarmsService.alarms');
                var index = this.get('currentModel.index');
                var _alarm = alarms.objectAt(index);
                alarm.isEnabled = _alarm.isEnabled;
                alarms.splice(index, 1);
                alarms.push(alarm);
                this.set('alarmsService.alarms', alarms);
                this.get('alarmsService').saveAlarms();
                this.transitionTo('alarms');
            }
        }
    });
});