define('alarm-clock/routes/alarms', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        /**
         * A container for the alarms service.
         *
         * @property alarms
         * @type {Service}
         */
        alarms: _ember['default'].inject.service('alarms'),

        /**
         * Generates the route's model.
         *
         * @method model
         * @return {Array}
         */
        model: function model() {
            return this.get('alarms.alarms');
        },

        actions: {
            /**
             * Delete an alarm.
             *
             * @method deleteAlarm
             * @param {Number} index The index of the alarm to remove.
             * @return {Void}
             */
            deleteAlarm: function deleteAlarm(index) {
                var service = this.get('alarms');
                service.get('alarms').removeAt(index);
                service.saveAlarms();
            },
            /**
             * Edit an alarm.
             *
             * @method editAlarm
             * @param {Number} index The index of the alarm to edit.
             * @return {Void}
             */
            editAlarm: function editAlarm(index) {
                this.transitionTo('edit', index);
            }
        }
    });
});