define('alarm-clock/services/time', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        /**
         * The current time.
         *
         * @property time
         * @type {DateTime}
         */
        now: new Date(),

        /**
         * The current time displayed in hh:mm<small>a</small>.
         *
         * @property time
         * @type {String}
         */
        time: (function () {
            var now = this.get('now');
            var hours = 0;
            var minutes = 0;
            if (now) {
                hours = now.getHours();
                minutes = now.getMinutes();
            }
            var ampm = hours > 11 ? 'PM' : 'AM';

            if (hours > 12) {
                hours = hours - 12;
            } else if (hours === 0) {
                hours = 12;
            }
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            return hours + ':' + minutes + '<small>' + ampm + '</small>';
        }).property('now'),

        /**
         * The current date displayed as D, M d, Y.
         *
         * @property date
         * @type {String}
         */
        date: (function () {
            var now = this.get('now');
            var weekDays = this.get('weekDays');
            var months = this.get('months');
            var day = weekDays[now.getDay()];
            var month = months[now.getMonth()];
            var date = now.getDate();
            var year = now.getFullYear();

            return day + ', ' + month + ' ' + date + ', ' + year;
        }).property('now'),

        /**
         * The possible weekdays.
         *
         * @property weekDays
         * @type {Array}
         */
        weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

        /**
         * The possible month names.
         *
         * @property months
         * @type {Array}
         */
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

        /**
         * Set a timer to set the time next.
         *
         * @method setTimer
         * @param {Number} seconds The number of seconds to set the timer for.
         * @return {Void}
         */
        setTimer: function setTimer(seconds) {
            var self = this;
            _ember['default'].run.later(function () {
                self.setTime();
            }, seconds * 1000);
        },

        /**
         * Set the current time.
         *
         * @method setTime
         * @return {Void}
         */
        setTime: (function () {
            var _now = this.get('now');
            var past = 0;
            var now = 0;
            if (_now) {
                past = _now.getMinutes();
                now = _now.getMinutes();
                this.set('now', new Date());
            }
            if (past !== now) {
                this.setTimer(60);
            } else {
                this.setTimer(1);
            }
        }).on('init')
    });
});