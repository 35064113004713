define('alarm-clock/components/touch-to', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    /**
     * Default tag name applied to the component.
     *
     * @property tagName
     * @type {String}
     */
    tagName: 'a',

    /**
     * When the touchStart event is sent from the component.
     *
     * @method touchStart
     * @return {Void}
     */
    touchStart: function touchStart() {
      var context = this.container.lookup('controller:application');
      var destination = this.get('destination');
      context.transitionTo(destination);
    }
  });
});