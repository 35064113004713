define('alarm-clock/routes/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        /**
         * A container for the alarms service.
         *
         * @property alarms
         * @type {Service}
         */
        alarms: _ember['default'].inject.service('alarms'),

        actions: {
            /**
             * Creates a new alarming.
             *
             * @method createNewAlarm
             * @param {Object} alarm The data to apply to the new alarm
             * @return {Void}
             */
            createNewAlarm: function createNewAlarm(alarm) {
                var alarms = this.get('alarms.alarms');
                alarm.isEnabled = true;
                alarms.push(alarm);
                this.set('alarms.alarms', alarms);
                this.get('alarms').saveAlarms();
                this.transitionTo('alarms');
            }
        }
    });
});