define('alarm-clock/components/prepare-alarm', ['exports', 'ember'], function (exports, _ember) {

  // This file is here because iOS will not play audio unless it is initiated by
  // a user interaction. This component forces the user to tap the screen, which
  // plays and pauses the sound so that it can be played programatically later.
  exports['default'] = _ember['default'].Component.extend({
    /**
     * A list of classes applied to the component.
     *
     * @property classNames
     * @type {Array}
     */
    classNames: ['prepare-alarm'],

    /**
     * If the audio is prepared.
     *
     * @property
     * @type {}
     */
    prepared: false,

    /**
     * A container for the sound service.
     *
     * @property soundService
     * @type {Service}
     */
    soundService: _ember['default'].inject.service('sound'),

    /**
     * When the user touches the component.
     *
     * @method touchStart
     * @return {Void}
     */
    touchStart: function touchStart() {
      var service = this.get('soundService');
      service.prepare();
      this.set('prepared', true);
    }
  });
});