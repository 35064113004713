define('alarm-clock/components/active-alarm', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        /**
         * The classes that are applied to the Component.
         *
         * @property classNames
         * @type {array}
         */
        classNames: ['active-alarm'],

        /**
         * A container for the time service.
         *
         * @property timeService
         * @type {Service}
         */
        timeService: _ember['default'].inject.service('time'),

        /**
         * A container for the alarms service.
         *
         * @property alarmsService
         * @type {Service}
         */
        alarmsService: _ember['default'].inject.service('alarms'),

        /**
         * A container for the sound service.
         *
         * @property soundService
         * @type {Service}
         */
        soundService: _ember['default'].inject.service('sound'),

        /**
         * When the alarm was last stopped.
         *
         * @property stopped
         * @type {DateTime}
         */
        stopped: null,

        /**
         * If snoozing has completed.
         *
         * @property doneSnoozing
         * @type {Boolean}
         */
        doneSnoozing: false,

        /**
         * If the alarm should currently be activated.
         *
         * @property alarming
         * @type {Boolean}
         */
        alarming: _ember['default'].computed('timeService.now', 'stopped', 'doneSnoozing', function () {
            var now = this.get('timeService.now');
            var stopped = this.get('stopped');
            if (this.get('doneSnoozing')) {
                return true;
            }
            if (stopped && stopped.getTime() + 61000 >= now.getTime()) {
                return false;
            }
            var weekDays = this.get('timeService.weekDays');
            var today = weekDays[now.getDay()].toLowerCase();
            var alarm = this.get('alarmsService.alarms').filter(function (value) {
                if (!value.isEnabled) {
                    return false;
                }
                if (!value.selectedDays[today]) {
                    return false;
                }
                if (value.hours !== now.getHours()) {
                    return false;
                }
                if (value.minutes !== now.getMinutes()) {
                    return false;
                }
                return true;
            });
            if (!alarm[0]) {
                return false;
            }
            return true;
        }),

        /**
         * Toggles the playing of the alarm sound.
         *
         * @method toggleSound
         * @return {Void}
         */
        toggleSound: (function () {
            var alarming = this.get('alarming');
            var sound = this.get('soundService');
            var playing = this.get('soundService.playing');
            if (alarming && !playing) {
                if (!sound) {
                    return;
                }
                sound.play();
                return;
            }
            if (!alarming && playing) {
                sound.stop();
                return;
            }
        }).observes('alarming'),

        actions: {
            /**
             * Trigger snoozing.
             *
             * @method snooze
             * @return {Void}
             */
            snooze: function snooze() {
                var _this = this;

                this.set('doneSnoozing', false);
                var service = this.get('alarmsService');
                service.set('snooze', this.get('timeService.now'));
                this.set('stopped', this.get('timeService.now'));
                _ember['default'].run.later(function () {
                    if (_this.get('isDestroyed')) {
                        return;
                    }
                    service.set('snooze', null);
                    _this.set('doneSnoozing', true);
                }, 10 * 60 * 1000);
            },

            /**
             * Stop the alarm.
             *
             * @method stop
             * @return {Void}
             */
            stop: function stop() {
                this.set('doneSnoozing', false);
                this.set('stopped', this.get('timeService.now'));
            }
        }
    });
});