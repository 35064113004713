define('alarm-clock/router', ['exports', 'ember', 'alarm-clock/config/environment'], function (exports, _ember, _alarmClockConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _alarmClockConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('alarms');
    this.route('new');
    this.route('edit', {
      path: '/edit/:index'
    });
  });

  exports['default'] = Router;
});