define('alarm-clock/services/sound', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        /**
         * If the sound is currently playing.
         *
         * @property playing
         * @type {Boolean}
         */
        playing: false,

        /**
         * Prepare the audio. Necessary for iOS devices.
         *
         * @method prepare
         * @return {Void}
         */
        prepare: function prepare() {
            var self = this;
            self.play();
            _ember['default'].run.next(function () {
                self.stop();
            });
        },

        /**
         * Play the sound.
         *
         * @method play
         * @return {Void}
         */
        play: function play() {
            this.set('playing', true);
            _ember['default'].$('audio')[0].play();
        },

        /**
         * Pause and rewind the sound.
         *
         * @method stop
         * @return {Void}
         */
        stop: function stop() {
            this.set('playing', false);
            _ember['default'].$('audio')[0].pause();
            _ember['default'].$('audio')[0].currentTime = 0;
        }
    });
});